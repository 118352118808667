:root {
  --white: #fff;
  --oh-orange: rgba(242, 94, 53, 1);
  --oh-white: #f5f5f5;
  --oh-off-white: #f8f8f8; /* for payment card */
  --oh-black: #555555;
  --oh-black-light: #717171;
  --oh-blue: #66b5de;
  --oh-teal: #95c48c;
  --oh-disabled: #d3d3d3;
  --oh-red: #ef5c34;
  --oh-green: #a3c996;
  --oh-vl-blue: rgba(102, 181, 222, 0.1);
  --oh-gray: rgba(0, 0, 0, 0.12);
  --oh-border-gray: rgba(0, 0, 0, 0.05);
  --oh-shadow-gray: rgba(64, 64, 64, 0.3);
  --oh-box-gray: #fafafa;
  --oh-game-black: #15202c;
  --oh-orange-new: #f15d34;
  --oh-blue-new: #cdeeff;
  --oh-pink-new: #ffd1de;
  --oh-purple-new: #e0dcf4;

  --oh-brand-1: rgba(242, 183, 5, 1);
  --oh-brand-vl-1: rgba(242, 183, 5, 0.1);
  --oh-brand-1-dark: #8c6a03;
  --oh-brand-2: rgba(140, 86, 55, 1);
  --oh-brand-vl-2: rgba(140, 86, 55, 0.1);
  --oh-brand-3: rgba(132, 108, 217, 1);
  --oh-brand-vl-3: rgba(132, 108, 217, 0.1);
  --oh-brand-4: rgba(232, 125, 124, 1);
  --oh-brand-vl-4: rgba(191, 99, 105, 0.1);
  --oh-brand-4-dark: #b2605f;
  --oh-brand-5: rgba(147, 204, 88, 1);
  --oh-brand-vl-5: rgba(147, 204, 88, 0.1);
  --oh-brand-6: rgba(94, 174, 1, 1);
  --oh-brand-vl-6: rgba(94, 174, 1, 0.1);
  --oh-brand-6-dark: #427a01;
  --oh-brand-7: rgba(11, 158, 217, 1);
  --oh-brand-vl-7: rgba(11, 158, 217, 0.1);
  --oh-brand-7-dark: #0879a6;
  --oh-brand-8: rgba(255, 118, 107, 1);
  --oh-brand-vl-8: rgba(255, 118, 107, 0.1);
  --oh-brand-9: rgba(68, 137, 171, 1);
  --oh-brand-vl-9: rgba(68, 137, 171, 0.1);
  --oh-brand-10: rgba(184, 39, 65, 1);
  --oh-brand-vl-10: rgba(184, 39, 65, 0.1);

  --oh-modal-bg: #fafafa;
  --oh-button-bg: rgba(11, 158, 217, 1);
  --oh-button-text-color: #fff;
}

@font-face {
  font-family: Avenir;
  src: local('Avenir Next'), url('./assets/fonts/AvenirReg.ttf');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Avenir';
  background-color: #f6f8fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
